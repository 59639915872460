import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useStateValue } from "../context/StateProvider";
import { 
  fetchTargetUserSubscriptionStatus, 
  uploadFilesToStorage, 
  fetchTargetUserContent, 
  deleteFileFromFirestoreAndStorage,
  requestSubscriptionApproval,
  getUserFileCount, 
  getContentPageLinkAndQr,
  subscribeToUserContent,
  requestContentApproval,
  fetchTargetUserContentRealtime,
  saveDeliveryInfoToFirestore,
  fetchDeliveryInfo,
  fetchInitialDeliveryData,
  deletePlakaTalep,
  handleQrPlateStatus,
  fetchQrPlateStatus,
  fetchQrPlateStatusByUserIds,
  subscribeToQrPlateStatus
} from '../utils/FirebaseFunction';
import LoginForm from './LoginForm';
import { useUser } from './context/UserContext'; // useUser hook'unu içeri aktarın
import PendingContents from './PendingContents';
import DeliveryForm from './DeliveryForm';

const SubscriberComponent = () => {
  const [{ user }] = useStateValue();
  const [videoFile, setVideoFile] = useState(null);
  const [imageFiles, setImageFiles] = useState([]);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [uploadedContent, setUploadedContent] = useState([]);
  const [expiryDate, setExpiryDate] = useState('Lütfen Üye Olunuz');
  const [fileCount, setFileCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');
  const videoInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const [contentPageLink, setContentPageLink] = useState('');
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const { subscriberInfos } = useUser();
  const targetUserId = subscriberInfos.id;
  // Add a state to manage the visibility of the DeliveryForm
  const [showDeliveryForm, setShowDeliveryForm] = useState(false);
  const [showPlakaTalepForm, setShowPlakaTalepForm] = useState(false);
  const [showPlakaStatus, setShowPlakaStatus] = useState(false);
  const [showQrSection, setShowQrSection] = useState(false);
  const [showContentUploadSection, setShowContentUploadSection] = useState(false);
  const [deliveryInfoForPlaka, setDeliveryInfoForPlaka] = useState({name: "", surname: "", phone: "",address: ""});
  const [plakaTalepMessage, setPlakaTalepMessage] = useState('');
  const [currentRequests, setCurrentRequests] = useState([]);
  
  

  useEffect(() => {
    if (user?.uid) {
      const handleContentUpdate = (content) => {
        setUploadedContent(content); // Güncellenen içerikleri state'e kaydet
      };

      const fetchSubscriptionStatus = async () => {
        const subscriptionStatus = await fetchTargetUserSubscriptionStatus(user.uid, targetUserId);

        setHasSubscription(subscriptionStatus.isActive);
        setExpiryDate(
          subscriptionStatus.expiryDate
            ? new Date(subscriptionStatus.expiryDate).toLocaleDateString('tr-TR')
            : 'Lütfen üye olunuz'
        );
      };

      // Abonelik durumunu getir
      fetchSubscriptionStatus();

      // Gerçek zamanlı dinleme başlat
      const unsubscribe = fetchTargetUserContentRealtime(
        user.hashedUserId,
        targetUserId,
        handleContentUpdate
      );

      // Cleanup: Component unmount olduğunda dinlemeyi durdur
      return () => unsubscribe();
    }
  }, [user?.uid, subscriberInfos, targetUserId]); 

  useEffect(() => {
    if (user?.uid) {
      // const fetchFileCount = async () => {
      //   const count = await getUserFileCount(user.hashedUserId
      //     , targetUserId);
      //   setFileCount(count);
      // };
      // fetchFileCount();

      const fetchContentPageLink = async () => {
        const contentPageLinkAndQr = await getContentPageLinkAndQr(user.uid, targetUserId);
        setContentPageLink(contentPageLinkAndQr.contentPageLink);
        setQrCodeUrl(contentPageLinkAndQr.contentPageQr);
      };
      fetchContentPageLink();
    }
  }, [user?.uid]);

  // Fetch the initial delivery data when the component is loaded
  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const initialData = await fetchInitialDeliveryData(user.uid, targetUserId);  // Fetch data
        if (initialData) {
          setDeliveryInfoForPlaka(initialData);  // Store the fetched data in state
        }
      } catch (error) {
        console.error("Error fetching initial delivery data:", error);
      }
    };

    loadInitialData();
  }, []);  // Empty dependency array ensures this runs only once on component load

  useEffect(() => {
    if (!user?.uid || !targetUserId) return;

    const unsubscribe = subscribeToQrPlateStatus(
      user.uid,
      targetUserId,
      (response) => {
        if (response.success) {
          setCurrentRequests(response.data);
        } else {
          setPlakaTalepMessage(response.message);
          setCurrentRequests(null);
        }
      }
    );

    // Component unmount olduğunda dinleyiciyi kaldır
    return () => unsubscribe();
  }, [user.uid, targetUserId]);

  const handleVideoChange = (event) => setVideoFile(event.target.files[0]);

  const handleImageChange = (event) => setImageFiles(Array.from(event.target.files));

  const handleUpload = async () => {
    if (!targetUserId) return console.error("Target User ID is not defined. Please ensure the target user is created.");
    if (!hasSubscription) return console.error("You need an active subscription to upload content.");
  
    // Firestore'dan mevcut video ve resim sayılarını al
    const { videoCount, imageCount } = await getUserFileCount(user.hashedUserId, targetUserId);

    // Yeni yüklenen dosyaların sayılarını hesapla
    const newVideoCount = videoFile ? 1 : 0;
    const newImageCount = imageFiles.length;

  // Toplam dosya kontrolü
  if (videoCount + newVideoCount > 1 || imageCount + newImageCount > 4) {
    setUploadMessage("En fazla 1 video ve 4 resim yükleyebilirsiniz.");
    setTimeout(() => setUploadMessage(''), 3000);
    setVideoFile(null);
    setImageFiles([]);
    // Dosya sınırı aşıldığında inputları temizle
    videoInputRef.current.value = null;  // Video input'unu sıfırla
    imageInputRef.current.value = null;  // Resim input'unu sıfırla
    return;
  }

    if (!videoFile && imageFiles.length === 0){ 
      setUploadMessage("Lütfen yüklemek için en az bir dosya seçin");
      setTimeout(() => setUploadMessage(''), 3000);
      return
      // return console.error("Please select at least one file to upload.");
    }
  
    setLoading(true);
    try {
      const { videoPath, imagePaths } = await uploadFilesToStorage(user.hashedUserId, targetUserId, videoFile, imageFiles);
  
      // Yeni içerik pendingContent koleksiyonuna ekleniyor
      const pendingContent = {
        userId: user.uid,
        userName: user.displayName,
        userEmail: user.email,
        hashedUserId: user.hashedUserId,
        targetUserId,
        media: [
          // Video path geçerliyse ekle
          ...(videoPath ? [{ type: "video", path: videoPath }] : []),
          // Resim path'lerini ekle
          ...imagePaths.map((path) => ({
            type: "image",
            path,
          })),
        ],
        createdAt: new Date().toISOString(),
      };
      const { message } = await requestContentApproval(pendingContent);
  
      setVideoFile(null);
      setImageFiles([]);
      videoInputRef.current.value = "";
      imageInputRef.current.value = "";
  
      setUploadMessage(message);
      setTimeout(() => setUploadMessage(''), 3000);
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setLoading(false);
    }
  };  

  const handleDelete = async (contentId, filePath) => {
    try {
      await deleteFileFromFirestoreAndStorage(contentId, user.hashedUserId, targetUserId,  filePath);
      const updatedFileCount = await getUserFileCount(user.hashedUserId, targetUserId);
      setFileCount(updatedFileCount);

      const updatedContent = await fetchTargetUserContent(user.hashedUserId, targetUserId);
      setUploadedContent(updatedContent);

    } catch (error) {
      console.error("Error deleting content:", error);
    }
  };

  const fetchInitialDeliveryInfo = async () => {
    try {
      return await fetchInitialDeliveryData(user.uid, targetUserId); // Delivery bilgilerini al
    } catch (error) {
      console.error('Teslimat bilgisi alınırken hata oluştu:', error);
      return null;
    }
  };

  // Callback function to update delivery info in SubscriberComponent state
  const handleDeliveryInfoUpdate = (updatedDeliveryInfo) => {
    setDeliveryInfoForPlaka(updatedDeliveryInfo);
    console.log("Updated delivery info:", updatedDeliveryInfo);
    // You can also save it to Firestore here if needed
    return true
  };

  const handleDeliveryFormSubmit = async (deliveryInfo) => {  
    try {
      // Teslimat bilgilerini Firestore'a kaydet
      const response = await saveDeliveryInfoToFirestore(user.uid, targetUserId, deliveryInfo);
      
      if (response.success) {
        // Teslimat bilgisi başarıyla kaydedildiyse
        alert("Teslimat bilgisi başarıyla güncellendi!");
        setShowDeliveryForm(false); // Formu gizle
        return true;  // Başarı durumu
      } else {
        // Teslimat bilgisi kaydedilemediyse
        alert("Teslimat bilgisi güncellenirken hata oluştu.");
        return false;  // Hata durumu
      }
    } catch (error) {
      // Hata durumunda
      console.error("Teslimat bilgisi kaydedilemedi:", error);
      alert("Teslimat bilgisi güncellenirken hata oluştu.");
      return false;  // Hata durumu
    }
  };
  
  const handleQrPlateStatusClick = async () => {
    // Bilgilerin doluluğunu kontrol et
    if (
      !deliveryInfoForPlaka.name.trim() ||
      !deliveryInfoForPlaka.surname.trim() ||
      !deliveryInfoForPlaka.phone.trim() ||
      !deliveryInfoForPlaka.address.trim()
    ) {
      setPlakaTalepMessage("Lütfen tüm bilgileri doldurun!");
      setTimeout(() => setPlakaTalepMessage(''), 3000);
      return;
    }
  
    try {
      // Plaka talebini Firestore'a kaydet
      const response = await handleQrPlateStatus(user.uid, targetUserId, deliveryInfoForPlaka, qrCodeUrl, contentPageLink); // Firebase function çağrısı
      // console.log("deliveryInfoForPlaka : ", deliveryInfoForPlaka)

      // Mesajı al ve set et
      if (response.success) {
        setPlakaTalepMessage(response.message); // Başarı mesajını ayarla

        // Firestore'dan mevcut talepleri tekrar çek
        const fetchResponse = await fetchQrPlateStatusByUserIds(user.uid, targetUserId);
        
        if (fetchResponse.success) {
              setCurrentRequests(fetchResponse.data); // Yeni talepleri state'e kaydet
            } else {
              setPlakaTalepMessage(fetchResponse.message); // Hata mesajını göster
            }
            setTimeout(() => setPlakaTalepMessage(''), 3000);
      } else {
        setPlakaTalepMessage(response.message); // Hata mesajını ayarla
      }
      setTimeout(() => setPlakaTalepMessage(''), 3000);
  
    } catch (error) {
      setPlakaTalepMessage("Plaka talep sırasında hata oluştu!");
      setTimeout(() => setPlakaTalepMessage(''), 3000);
      console.error("Plaka talebi sırasında hata oluştu:", error);
    }
  };

  const handleDeletePlateRequest = async (plateRequestId) => {
    try {
      // Talebi Firestore'dan silme işlemi
      await deletePlakaTalep(plateRequestId); // Firestore işleviniz burada çağrılır.
      setPlakaTalepMessage("Talep başarıyla silindi!");
      setTimeout(() => setPlakaTalepMessage(''), 3000);
  
      // Silinen belgeyi listeden kaldır
      setCurrentRequests((prevRequests) =>
        prevRequests.filter((request) => request.plateRequestId !== plateRequestId)
      );
    } catch (error) {
      console.error("Talep silinemedi:", error);
      setPlakaTalepMessage("Talep silinirken hata oluştu!");
      setTimeout(() => setPlakaTalepMessage(''), 3000);
    }
  };
  
  
  return (
    <>
      {/* Main Div */}
      <div className="max-w-4xl mx-auto mt-4 p-6 bg-white rounded-lg shadow-lg">

        {/* LoginForm Butonu */}
        <div className='bg-blue-100 p-2 mb-2 flex justify-end'>
         <LoginForm buttonClass=" top-2 right-2" />   
        </div>

        {/* Hoşgeldiniz Kısmı */}
        <div className='bg-orange-100 p-2 mb-2'>
          <h1 className="text-xl font-semibold mb-4">Hoşgeldiniz Sayın, {user.displayName}</h1>
          <p className="text-lg">
            Üyeliklik Alınan Kişi: <span className="font-semibold">{subscriberInfos.name} {subscriberInfos.surname}</span>
          </p>
          {/* <p className="text-lg">
            Üyelik durumu: <span className="font-semibold">{hasSubscription ? 'Üye' : 'Üye değil'}</span>
          </p> */}
          <p className="text-lg mb-2">
            Üyelik sonlanma tarihi: <span className="font-semibold">{expiryDate}</span>
          </p>
        </div>

        {/* Delivery Form Alanı */}
        <div className='bg-orange-300 p-2 mb-2'>
          {/* DeliveryForm link - tıklanınca görülsün */}
          {/* <button
            onClick={() => setShowDeliveryForm(!showDeliveryForm)}
            className="bg-blue-500 text-white py-2 px-4 rounded-md mb-4"
          >
            Teslimat Bilgileri
          </button> */}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault(); // Bağlantının varsayılan davranışını engeller
                setShowDeliveryForm(!showDeliveryForm);
              }}
              className="font-semibold text-lg underline hover:bg-green-300 transition-colors duration-200"
            >
              Teslimat Bilgileri
            </a>
                    {/* Delivery Form */}
        {showDeliveryForm && (
          <DeliveryForm 
            onSubmit={handleDeliveryFormSubmit} 
            fetchInitialDeliveryInfo={fetchInitialDeliveryInfo}
            onDeliveryInfoUpdate={handleDeliveryInfoUpdate} 
          />
        )}
        </div>

        {/* Plaka Blgileri */}        
        {/* Plaka Talebi için Bilgiler */}
        <div className="bg-orange-300 p-2 mb-2">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault(); // Bağlantının varsayılan davranışını engeller
                  setShowPlakaTalepForm(!showPlakaTalepForm);
                }}
                className="font-semibold text-lg underline hover:bg-green-300 transition-colors duration-200"
              >
                Plaka Talep Edin </a>

              {showPlakaTalepForm && (
                <div>
                  <p>Plaka, talebi yaptığınızda aşağıdaki gösterilen alıcı bilgilerine iletilecektir.</p>
                  <p className='mb-2'>Farklı alıcı bilgilerine gönderilmesini istiyorsanız lütfen teslimat bilgileri kısmından bilgileri güncelleyiniz.</p>
                    <table className="table-auto mb-4">
                      <tbody>
                        <tr>
                          <td className="px-2 font-semibold flex justify-between">İsim / Soyisim <span className=" ml-2"> : </span></td>
                          <td className="px-2">{deliveryInfoForPlaka.name} {deliveryInfoForPlaka.surname}</td>
                        </tr>
                        <tr>
                          <td className="px-2 font-semibold flex justify-between">Telefon <span className="ml-2"> :</span></td>
                          <td className="px-2">{deliveryInfoForPlaka.phone}</td>
                        </tr>
                        <tr>
                          <td className="px-2 font-semibold flex justify-between">Adres <span className="ml-2"> :</span></td>
                          <td className="px-2">{deliveryInfoForPlaka.address}</td>
                        </tr>
                      </tbody>
                    </table>
                    <button
                      onClick={handleQrPlateStatusClick}
                      className="mt-2 px-1 py-1 bg-lime-700 text-white rounded-md hover:bg-green-500"
                    >
                      Plaka Talep Et
                    </button>
                  {plakaTalepMessage && (
                    <div className="text-green-700 font-bold text-lg text-center my-4">
                      {plakaTalepMessage}
                    </div>
                  )}
                </div>)}
        </div>          

        {/* Plaka Bilgileri */}          
        <div className="bg-blue-100 p-2 mb-2">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault(); // Bağlantının varsayılan davranışını engeller
              setShowPlakaStatus(!showPlakaStatus);
            }}
            className="font-semibold text-lg underline mb-2 hover:bg-green-300 transition-colors duration-200"
          >
            Plaka Durumu
          </a>
          {showPlakaStatus ? (
            currentRequests && currentRequests.length > 0 ? (
            <div className="overflow-x-scroll scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
              <table className="table-auto w-full border-collapse border border-gray-300 mb-4">
                <thead>
                  <tr className='text-sm sm:text-base'>
                    <th className="border border-gray-300 font-semibold">İsim</th>
                    <th className="border border-gray-300 px-1 font-semibold">Soyisim</th>
                    <th className="border border-gray-300 px-1 font-semibold">Telefon</th>
                    <th className="border border-gray-300 px-1 font-semibold">Adres</th>
                    <th className="border border-gray-300 px-1 font-semibold">Durum</th>
                    <th className="border border-gray-300 px-1 font-semibold">Kod</th>
                  </tr>
                </thead>
                <tbody>
                  {currentRequests.map((request) => (
                    <tr className='text-sm sm:text-base' key={request.plateRequestId}>
                      <td className="border border-gray-300">{request.name}</td>
                      <td className="border border-gray-300 px-1">{request.surname}</td>
                      <td className="border border-gray-300 px-1">{request.phone}</td>
                      <td className="border border-gray-300 px-1">{request.address}</td>
                      <td className="border border-gray-300 px-1">{request.status}</td>
                      {request.status === "TransferCodeSent" && request.transferCode && (
                        <td className="border border-gray-300 px-4 py-2 text-center">{request.transferCode}</td>
                      )}
                      {request.status === "Bekliyor" && (
                        <td className="border border-gray-300 px-4 py-2 text-center">
                          <button
                            onClick={() => handleDeletePlateRequest(request.plateRequestId)}
                            className="px-2 py-1 bg-red-600 text-white text-sm rounded hover:bg-red-700"
                          >
                            Sil
                          </button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            ) : (
              <h2 className="mt-1">Plaka talebi veya plakanız bulunmamaktadır.</h2>
            )
          ) : null}

        </div>

        {/* Link ve Qr */}
        <div className='bg-amber-200 p-2 mb-2'>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault(); // Bağlantının varsayılan davranışını engeller
              setShowQrSection(!showQrSection);
            }}
            className="font-semibold text-lg underline mb-2 hover:bg-green-300 transition-colors duration-200"
          >
            QR Durumu
          </a>

          {showQrSection && (
            <div>
              {/* Page Url */}
              {qrCodeUrl && (
                <div className="text-center">
                  <p className="text-lg mb-2">İçerik sayfasına erişmek için QR kodunu tarayın:</p>
                  <div className="flex justify-center">
                    <img src={qrCodeUrl} alt="User Content QR Code" className="w-32 h-32" />
                  </div>
                </div>
              )}

              {/* Page Link */}
              {contentPageLink && (
                <>
                  <h2 className="text-lg text-decoration-line: underline">İçerik Sayfanızın Linki</h2>
                  {/* <p className="text-lg">Bu Linki Paylaşabilirsiniz: </p> */}
                  <p className="break-all mb-2 text-sm sm:text-base">
                    <a className="break-words text-center bg-amber-300 hover:bg-green-300"
                      href={contentPageLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      >
                        {contentPageLink}
                      </a>
                  </p>
                </>
              )}
            </div>
          )}
        </div>

        {/* İçerik Yükleme */}
        <div className='bg-lime-200 mb-2 p-2' style={{ display: !hasSubscription ? 'none' : 'block' }}>
        <a
            href="#"
            onClick={(e) => {
              e.preventDefault(); // Bağlantının varsayılan davranışını engeller
              setShowContentUploadSection(!showContentUploadSection);
            }}
            className="font-semibold text-lg underline mb-2 hover:bg-green-300 transition-colors duration-200"
          >
            İçerik Yükle
          </a>
          {showContentUploadSection && (
            <div>
                <div className="mb-2">
                  <label className="block">Video Yükleyin:</label>
                  <input
                    type="file"
                    accept="video/*"
                    ref={videoInputRef}
                    onChange={handleVideoChange}
                    className="block w-full text-sm text-gray-500 border border-gray-300 rounded-lg cursor-pointer hover:border-blue-500 focus:outline-none focus:ring focus:ring-green-200"
                    disabled={!hasSubscription}
                    placeholder="Dosya seç"
                  />
                </div>

                <div className="mb-4">
                  <label className="block">Resim Yükleyin:</label>
                  <input
                    lang="tr"
                    type="file"
                    accept="image/*"
                    multiple
                    ref={imageInputRef}
                    onChange={handleImageChange}
                    className="block w-full text-sm text-gray-500 border border-gray-300 rounded-lg cursor-pointer hover:border-blue-500 focus:outline-none focus:ring focus:ring-blue-200"
                    disabled={!hasSubscription}
                    aria-label="Dosya seç"
                  />
                </div>

                <button
                  onClick={handleUpload}
                  className="w-full py-2 px-4 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-200 mb-1"
                  disabled={!hasSubscription}
                >
                  İçerik Yükle
                </button>

                {uploadMessage && (
                <div className="text-green-700 font-bold text-lg text-center my-4">
                  {uploadMessage}
                </div>
                  )}
                {loading && (
                  <div className="flex justify-center my-4">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-green-600"></div>
                  </div>
                )}
            </div>
          )}
        </div>

        {/* Onay Bekleyen İçerikleri Listeme Modülü */}
        <div className='bg-blue-200 mb-2 p-2'>
          <PendingContents targetUserId={targetUserId}/>
        </div>

        {/* Yüklenen İçerikleri Listeme Modülü */}
        <div className='bg-amber-500 p-2'>
          <h1 className="text-xl font-bold mb-4">Onaylanan İçerikler</h1>
          <div className="flex flex-wrap gap-4 p-4">
            {uploadedContent.flatMap((content) =>
              content.media.map((mediaItem, idx) => (
                <div key={`${content.id}-${idx}`} className="relative">
                  {mediaItem.type === "image" ? (
                    <img
                      src={mediaItem.path}
                      alt={`media-${idx}`}
                      className="w-32 h-32 object-cover rounded-md"
                    />
                  ) : (
                    <video className="w-32 h-32 object-cover rounded-md" controls>
                      <source src={mediaItem.path} type="video/mp4" />
                    </video>
                  )}
                  <button
                    onClick={() => handleDelete(content.id, mediaItem.path)}
                    className="absolute top-2 right-2 bg-red-500 text-white py-1 px-2 rounded hover:bg-red-600 transition duration-200"
                  >
                    Sil
                  </button>
                </div>
              ))
            )}
          </div>
        </div>

      </div>
    </>
  );



};

export default SubscriberComponent;
