import React from 'react'

const UserCart = ({selectedUser}) => {
  return (
    <div className="bg-white p-4 rounded shadow-md mt-4">
        <h2 className="text-lg font-bold">{selectedUser.data.displayName}</h2>
        <p>Email: {selectedUser.data.email}</p>
        <p>Telefon: {selectedUser.data.phoneNumber || "Belirtilmemiş"}</p>
    </div>
  )
}

export default UserCart;