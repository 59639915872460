import React, { useEffect, useState } from 'react';
import { fetchPendingContentsRealtime } from '../utils/FirebaseFunction'; // Firebase fonksiyonunuzu içeri aktarın

const PendingContents = ({ targetUserId }) => {
  const [pendingContents, setPendingContents] = useState([]);

  useEffect(() => {
    const unsubscribe = fetchPendingContentsRealtime((contents) => {
      // Kullanıcı bazında içerikleri grupla
      const groupedContents = groupContentsByUser(contents);
      setPendingContents(groupedContents);
    });

    // Cleanup function for unsubscription
    return () => unsubscribe();
  }, []);

  // Kullanıcıya göre içerikleri gruplama fonksiyonu
  const groupContentsByUser = (contents) => {
    return contents.reduce((acc, content) => {
      const targetUserId = content.targetUserId; // Kullanıcıyı benzersiz kimlik olarak alıyoruz (ID veya isim olabilir)
      if (!acc[targetUserId]) {
        acc[targetUserId] = {
          userName: content.userName,
          media: [],
        };
      }
      acc[targetUserId].media = [...acc[targetUserId].media, ...content.media]; // Aynı kullanıcıya ait içerikleri birleştir
      return acc;
    }, {});
  };

  // Hedef kullanıcıya ait içerikleri filtrele
  const filteredContents = targetUserId ? pendingContents[targetUserId]?.media || [] : [];

  return (
    <>
      <h1 className="text-xl font-bold mb-4">Onay Bekleyen İçerikler</h1>
      {filteredContents.length === 0 ? (
        <p>Bu kullanıcı için bekleyen içerik bulunamadı.</p>
      ) : (
        <div className="flex flex-wrap gap-4 mt-2">
          {filteredContents.map((mediaItem, index) => (
            <div key={index} className="w-32 h-32">
              {mediaItem.type === 'image' ? (
                <img src={mediaItem.path} alt={`media-${index}`} className="object-cover w-full h-full rounded" />
              ) : (
                <video controls className="object-cover w-full h-full rounded">
                  <source src={mediaItem.path} type="video/mp4" />
                </video>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default PendingContents;
