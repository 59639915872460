import { Outlet, Navigate } from "react-router-dom";
import { useStateValue } from "../context/StateProvider";

// Admin için korumalı rota bileşeni
const AdminProtectedRoute = () => {
    const [{ user, userIdhasAccess }] = useStateValue();

    // Kullanıcı ve kullanıcı id'si eşleşiyorsa admin sayfasını göster
    if (user && userIdhasAccess.includes(user.uid)) {
        return <Outlet />;
    } else if (user) {
        return <Navigate to="/user" />; // Admin olmayan kullanıcıyı yönlendir
    } else {
        return <Navigate to="/" />; // Giriş yapmamış kullanıcıyı yönlendir
    }
};

// Kullanıcı için korumalı rota bileşeni
const UserProtectedRoute = () => {
    const [{ user, userIdhasAccess }] = useStateValue();

    // Kullanıcı yetkisi varsa kullanıcı sayfasını göster
    if (user && !userIdhasAccess.includes(user.uid)) {
        return <Outlet />;
    } else if (user) {
        return <Navigate to="/admin" />; // Admin olan kullanıcıyı yönlendir
    } else {
        return <Navigate to="/" />; // Giriş yapmamış kullanıcıyı yönlendir
    }
};

export { AdminProtectedRoute, UserProtectedRoute };
