import { useEffect, useState, useRef } from "react";
import {
  signInWithPopup,
  signOut,
  browserSessionPersistence,
  setPersistence,
} from "firebase/auth";
import { firebaseAuth, provider } from "../firebase-config";
import { useStateValue } from "../context/StateProvider";
import { actionType } from "../context/reducer";
import { saveUserToFirestore, getUserFromFirestore } from "../utils/FirebaseFunction";
import { useNavigate } from "react-router-dom";
// import AudioPlayer from "./Audioplayer";
import CryptoJS from "crypto-js"; // CryptoJS kütüphanesini import et

// User ID'yi hashlemek için bir fonksiyon
// const hashUserId = (userId) => {
//   // SHA-256 algoritmasıyla hashle
//   return CryptoJS.SHA256(userId).toString(CryptoJS.enc.Base64); // Hashi base64 formatında döndür
// };

const hashUserId = (userId) => {
  // SHA-256 algoritmasıyla hashle
  const hash = CryptoJS.SHA256(userId).toString(CryptoJS.enc.Base64);
  
  // URL-safe hale getirmek için özel karakterleri değiştir
  const safeHash = hash
    .replace(/\+/g, 'a')   // "+" karakterini "-" ile değiştir
    .replace(/\//g, 'b')   // "/" karakterini "_" ile değiştir
    .replace(/=+$/, 'c');    // "=" karakterini kaldır
   
  return safeHash; // URL-safe hash döndür
};



function LoginForm({ buttonClass }) {
  const [{ user, userIdhasAccess }, dispatch] = useStateValue();
  const [isLoggedin, setIsLoggedin] = useState(false);
  const navigate = useNavigate();

  // const audioRef = useRef(null); // Audio referansı

  useEffect(() => {
    setIsLoggedin(!!user);
  }, [user]);

  // useEffect(() => {
  //   if (user) {
  //     // Kullanıcı giriş yaptıysa ve /user sayfasına yönlendirdiysek müziği çal
  //     if (audioRef.current) {
  //       audioRef.current.play().catch((error) => {
  //         console.error("Müzik çalma hatası:", error);
  //       });
  //     }
  //   }else{
  //     audioRef.current.pause()
  //   }
  // }, [user]); // user değiştiğinde müziği çal

  const loginlogout = async () => {
    if (!user) {
      try {
        await setPersistence(firebaseAuth, browserSessionPersistence);
        const result = await signInWithPopup(firebaseAuth, provider);
        const providerData = result.user.providerData[0];
        const hashedUserId = hashUserId(providerData.uid);
  
        // Firestore'dan kullanıcıyı getir
        const firestoreUser = await getUserFromFirestore(providerData.uid);
  
        // Kullanıcıyı Dispatch Et
        dispatch({
          type: actionType.SET_USER,
          user: firestoreUser || { ...providerData, hashedUserId },
        });
  
        // Yönlendirme
        if (userIdhasAccess.includes(providerData.uid)) {         
          navigate("/admin");
        } else {         
          navigate("/user");
        }
      } catch (error) {
        console.error("Sign-in error:", error);
      }
    } else {
      try {
        await signOut(firebaseAuth);
        localStorage.removeItem("firebase:authUser:");
        sessionStorage.removeItem("firebase:authUser:");
  
        dispatch({
          type: actionType.SET_USER,
          user: null,
        });
  
        navigate("/");
      } catch (error) {
        console.error("Sign-out error:", error);
      }
    }
  };
  

  return (
    <>
      {/* <AudioPlayer ref={audioRef} /> */}

      {/* <button
        onClick={loginlogout}
        className={`px-4 py-2 bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 ${buttonClass}`}
        // className={`px-4 py-2 bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 ${buttonClass}`}
      >
        {isLoggedin ? "Çıkış Yap" : "Giriş Yap"}
      </button> */}

<button
  onClick={loginlogout}
  className={` ${!isLoggedin ? 'px-4 py-2' : 'px-2 py-1 text-sm'}  border-2 bg-greenTwo text-white hover:bg-greenOne border-gray-400 rounded flex items-center transition-colors duration-200 `}
>
  {!isLoggedin && (
    <img
      src="/images/google.svg"
      alt="Google logo"
      className="w-6 h-6 mr-2"
    />
  )}
  {isLoggedin ? "Çıkış Yap" : "Giriş Yap"}
</button>


    </>
  );
}

export default LoginForm;
