import React, { useState, useEffect } from "react";

const DeliveryForm = ({ onSubmit, fetchInitialDeliveryInfo, onDeliveryInfoUpdate  }) => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Buton durumunu kontrol eder

  useEffect(() => {
    const loadInitialData = async () => {
      const initialData = await fetchInitialDeliveryInfo();
      if (initialData) {
        setName(initialData.name || "");
        setSurname(initialData.surname || "");
        setPhone(initialData.phone || "");
        setAddress(initialData.address || "");
        setIsUpdating(true);
        setIsButtonDisabled(true); // Başlangıçta disable bırak
      }
    };
    loadInitialData();
  }, [fetchInitialDeliveryInfo]);

  useEffect(() => {
    // Formdaki alanlar değiştiğinde butonu kontrol et
    const hasChanges =
      name.trim() !== "" || surname.trim() !== "" || phone.trim() !== "" || address.trim() !== "";
    setIsButtonDisabled(!hasChanges);
  }, [name, surname, phone, address]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const deliveryInfo = { name, surname, phone, address };

    const success = await onSubmit(deliveryInfo); // onSubmit sonucunu bekle
    if (success) {
      setIsButtonDisabled(true); // Başarılıysa butonu tekrar disable yap
      onDeliveryInfoUpdate(deliveryInfo); // Güncel bilgileri üst bileşene gönder
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-lg font-bold mb-4">Teslimat Bilgileri</h2>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Ad</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="block w-full px-4 py-2 border rounded-md focus:ring focus:ring-blue-300"
          placeholder="Adınızı giriniz"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Soyad</label>
        <input
          type="text"
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
          className="block w-full px-4 py-2 border rounded-md focus:ring focus:ring-blue-300"
          placeholder="Soyadınızı giriniz"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Telefon</label>
        <input
          type="tel"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          className="block w-full px-4 py-2 border rounded-md focus:ring focus:ring-blue-300"
          placeholder="Telefon numaranızı giriniz"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Adres</label>
        <textarea
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="block w-full px-4 py-2 border rounded-md focus:ring focus:ring-blue-300"
          placeholder="Adresinizi giriniz"
          rows="4"
          required
        />
      </div>
      <button
        type="submit"
        disabled={isButtonDisabled} // Disable durumu
        className={`w-full py-2 px-4 rounded-md text-white ${
          isUpdating ? "bg-blue-600 hover:bg-blue-700" : "bg-green-600 hover:bg-green-700"
        } ${isButtonDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
      >
        {isUpdating ? "Güncelle ve Onayla" : "Adresi Girin"}
      </button>
    </form>
  );
};

export default DeliveryForm;
