import { useState } from "react";

const MediaPreview = ({ media, onApprove, onReject }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <div className="media-item flex items-center gap-4 border p-2 my-2 rounded shadow-sm">
      {/* Media Thumbnail */}
      <div
        className="cursor-pointer w-20 h-20 overflow-hidden rounded shadow-md"
        onClick={() => setModalOpen(true)}
      >
        {media.type === "image" ? (
          <img
            src={media.path}
            alt="Preview"
            className="w-full h-full object-cover"
          />
        ) : (
          <video
            src={media.path}
            className="w-full h-full object-cover"
            muted
          />
        )}
      </div>

      {/* Approve and Reject Buttons */}
      <div className="flex gap-2">
        <button
          onClick={onApprove}
          className="bg-green-500 text-white text-sm px-3 py-1 rounded hover:bg-green-600"
        >
          Onayla
        </button>
        <button
          onClick={onReject}
          className="bg-red-500 text-white text-sm px-3 py-1 rounded hover:bg-red-600"
        >
          Reddet
        </button>
      </div>

      {/* Modal for Enlarged View */}
      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={() => setModalOpen(false)}
        >
          <div
            className="relative bg-white rounded shadow-lg p-4"
            onClick={(e) => e.stopPropagation()} // Prevent modal close on content click
          >
            <button
              onClick={() => setModalOpen(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-black"
            >
              ✕
            </button>
            {media.type === "image" ? (
              <img
                src={media.path}
                alt="Preview Enlarged"
                className="max-w-full max-h-screen"
              />
            ) : (
              <video
                src={media.path}
                controls
                className="max-w-full max-h-screen"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MediaPreview;
